import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductGallery from './components/ProductGallery';
import PurchaseForm from './components/PurchaseForm';
import ThankYou from './components/ThankYou';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <ProductGallery />
              <PurchaseForm siteId={1} />
            </>
          } />
          <Route path="/tesekkurler" element={<ThankYou />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;