import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const value = params.get('value');
    const currency = params.get('currency') || 'TRY';

    if (value) {
      // Facebook Pixel Purchase Event
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: value,
          currency: currency
        });
      }

      // URL parametrelerini temizle
      params.delete('value');
      params.delete('currency');
      navigate({
        pathname: location.pathname,
        search: params.toString()
      }, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div class="container">
        <div class="card">
            <div class="checkmark">
                <svg viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
            </div>
            <h1>Teşekkürler!</h1>
            <p>Siparişiniz başarıyla alınmıştır.</p>
        </div>
    </div>
  );
};

export default ThankYou;
