import React, { useState } from 'react';
import axios from 'axios';
import ProductOptions from './ProductOptions';
import { useNavigate } from 'react-router-dom';

const PurchaseForm = ({ siteId }) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [formData, setFormData] = useState({
    sp_musteri: '',
    sp_telefon: '',
    sp_adres: '',
    sp_ulke: 'Türkiye',
    sp_sehir: '',
    sp_ilce: ''
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleOptionSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (!selectedProduct.urun_fiyat) {
      setError('Ürün fiyatı alınamadı. Lütfen bir ürün seçin.');
      return;
    }

    const dataToSubmit = {
      ...formData,
      paket: selectedProduct.baslik,
      urun_fiyat: selectedProduct.urun_fiyat,
      varyasyon: selectedProduct
    };

    console.log('Gönderilen veri:', dataToSubmit);

    axios.post('/api/submit_form', dataToSubmit)
      .then(response => {
        console.log('Sunucudan gelen cevap:', response.data);
        setMessage('Form başarıyla gönderildi.');
        setError('');
        setFormData({
          sp_musteri: '',
          sp_telefon: '',
          sp_adres: '',
          sp_ulke: 'Türkiye',
          sp_sehir: '',
          sp_ilce: ''
        });
        setSelectedProduct({});
        
        // Teşekkürler sayfasına yönlendirme
        navigate(`/tesekkurler?value=${selectedProduct.urun_fiyat}&currency=TRY`);
      })
      .catch(error => {
        console.error('Bir hata oluştu:', error);
        setMessage('');
        setError('Form gönderilirken bir hata oluştu.');
      });
  };

  return (
    <div className="purchase-form" id="purchase-form">
      <ProductOptions onSelectOption={handleOptionSelect} siteId={siteId} />
      <h3>Satın Alma Formu</h3>
      <div>
        <div>
          <label>İsim:</label>
          <input
            type="text"
            name="sp_musteri"
            value={formData.sp_musteri}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Telefon:</label>
          <input
            type="tel"
            name="sp_telefon"
            value={formData.sp_telefon}
            onChange={handleChange}
            required
            pattern="[0-9]{10}"
            title="Lütfen 10 haneli bir telefon numarası giriniz."
          />
        </div>
        <div>
          <label>Adres:</label>
          <input
            type="text"
            className='address'
            placeholder='Adresinizi eksiksiz giriniz. Örn: Mahalle, Cadde, Sokak, No: Şehir/İlçe'
            name="sp_adres"
            value={formData.sp_adres}
            onChange={handleChange}
            required
          />
        </div>
        <button type="button" onClick={handleSubmit}>Satın Al</button>
      </div>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PurchaseForm;
