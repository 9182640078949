import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProductOptions = ({ onSelectOption, siteId }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    axios.get(`/api/products/${siteId}`)
      .then(response => {
        const { paketler } = response.data;
        setProductOptions(paketler);
      })
      .catch(error => {
        console.error('There was an error fetching the product options!', error);
      });
  }, [siteId]);

  const handleChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedProduct = productOptions[selectedIndex];
    setSelectedOption(parseInt(selectedIndex, 10));
    onSelectOption(selectedProduct);
  };

  return (
    <div className="product-options">
      <h3>Ürün Seçenekleri</h3>
      {productOptions.map((option, i) => (
        <div key={i} style={{ marginBottom: '10px' }}>
          <label
            style={{
              background: '#fdfdfd',
              padding: '0px !important',
              cursor: 'pointer',
              border: '2px solid #95bacf',
              width: '100% !important'
            }}
            id={`product-${i}`}
            className="product-element product col-sm-12 col-xs-12"
            data-box-id={i}
          >
            <input
              type="radio"
              className="paket"
              data-paket={i + 1}
              name="paket"
              id={`paket-${i}`}
              style={{ border: '1px solid red', maxWidth: '100% !important', display: 'none' }}
              value={i}
              checked={selectedOption === i}
              onChange={handleChange}
            />
            <img
              src={`siparis/urun/${i + 1}_${selectedOption === i ? "2" : "1"}.png`}
              data-paket={i + 1}
              alt={`Paket ${i + 1}`}
            />
            {/* <span>{option.baslik} - {option.urun_fiyat} TL</span> */}
          </label>
        </div>
      ))}
    </div>
  );
};

export default ProductOptions;
