import React from 'react';

const images = [
  { id: 1, src: 'surup_01.png', alt: 'Ürün Resmi 1' },
  { id: 2, src: 'surup_02.png', alt: 'Ürün Resmi 2' },
  { id: 4, src: 'surup_03.png', alt: 'Ürün Resmi 3' },
  { id: 5, src: 'surup_04.png', alt: 'Ürün Resmi 4' },
  { id: 6, src: 'surup_05.png', alt: 'Ürün Resmi 5' },
  { id: 7, src: 'surup_06.png', alt: 'Ürün Resmi 6' },
  { id: 8, src: 'surup_07.png', alt: 'Ürün Resmi 7' },
  { id: 9, src: 'surup_08.png', alt: 'Ürün Resmi 8' },
  { id: 10, src: 'surup_09.png', alt: 'Ürün Resmi 9' },
  { id: 11, src: 'surup_10.png', alt: 'Ürün Resmi 10' },
  { id: 12, src: 'surup_12.png', alt: 'Ürün Resmi 11' },
  { id: 13, src: 'surup_13.png', alt: 'Ürün Resmi 11' },
  { id: 14, src: 'surup_14.png', alt: 'Ürün Resmi 11' },
  { id: 15, src: 'surup_15.png', alt: 'Ürün Resmi 11' },
  { id: 16, src: 'surup_16.png', alt: 'Ürün Resmi 11' },
  { id: 17, src: 'surup_17.png', alt: 'Ürün Resmi 11' },
  { id: 18, src: 'surup_18.png', alt: 'Ürün Resmi 11' },
];

function ProductGallery() {
  const handleImageClick = () => {
    const purchaseFormElement = document.getElementById('purchase-form');
    if (purchaseFormElement) {
      purchaseFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="product-gallery">
      {images.map(image => (
        <img
          key={image.id}
          src={image.src}
          alt={image.alt}
          className="product-image"
          onClick={handleImageClick}
        />
      ))}
    </div>
  );
}

export default ProductGallery;
